import M1 from '@assets/images/markers/map-marker-cluster.png';
import M2 from '@assets/images/markers/map-marker-cluster.png';
import M3 from '@assets/images/markers/map-marker-cluster.png';
import M4 from '@assets/images/markers/map-marker-cluster.png';
import M5 from '@assets/images/markers/map-marker-cluster.png';

export const MAP_OPTIONS = {
  mapId: process.env.GMAP_ID || '387b2dae642a706c',
  center: { lat: 51.8218586, lng: 4.7595114 },
  defaultZoom: 6,
  yesIWantToUseGoogleMapApiInternals: true,
  options: {
    mapId: process.env.GMAP_ID || '387b2dae642a706c',
    minZoom: 1,
    maxZoom: 15,
    backgroundColor: '#bbd9e6',
    disableDoubleClickZoom: false,
    disableDefaultUI: false,
    mapTypeControl: false,
    scaleControl: false,
    scrollwheel: true,
    panControl: false,
    streetViewControl: false,
    draggable: true,
    overviewMapControl: false,
    overviewMapControlOptions: {
      opened: false,
    },
  },
};

export const PILELIST_MAP_OPTIONS = {
  default_locations: [
    {
      name: 'Pile #1',
      lat: 51.822851984801595,
      lng: 4.755746750994336,
      position: null,
    },
    {
      name: 'Pile #2',
      lat: 51.822798933258404,
      lng: 4.7561544467646,
      position: null,
    },
    {
      name: 'Pile #3',
      lat: 51.8227525131069,
      lng: 4.756562142534864,
      position: null,
    },
    {
      name: 'Pile #4',
      lat: 51.82271272436751,
      lng: 4.756969838305127,
      position: null,
    },
    {
      name: 'Pile #5',
      lat: 51.822679567057854,
      lng: 4.757302432222974,
      position: null,
    },
    {
      name: 'Pile #6',
      lat: 51.82253367460542,
      lng: 4.755671649141919,
      position: null,
    },
    {
      name: 'Pile #7',
      lat: 51.822480622687415,
      lng: 4.756068616076123,
      position: null,
    },
    {
      name: 'Pile #8',
      lat: 51.82244746520693,
      lng: 4.7564655830103275,
      position: null,
    },
    {
      name: 'Pile #9',
      lat: 51.82239441318739,
      lng: 4.756862549944532,
      position: null,
    },
    {
      name: 'Pile #10',
      lat: 51.82236788715418,
      lng: 4.757227330370557,
      position: null,
    },
    {
      name: 'Pile #11',
      lat: 51.82222862522366,
      lng: 4.755564360781324,
      position: null,
    },
    {
      name: 'Pile #12',
      lat: 51.82218883602158,
      lng: 4.755982785387647,
      position: null,
    },
    {
      name: 'Pile #13',
      lat: 51.82214904678437,
      lng: 4.7563904811579105,
      position: null,
    },
    {
      name: 'Pile #14',
      lat: 51.82210925751201,
      lng: 4.756776719256055,
      position: null,
    },
    {
      name: 'Pile #15',
      lat: 51.822082731310914,
      lng: 4.75715222851814,
      position: null,
    },
  ],
};

const cluster_size = 40;
export const MAP_CLUSTER_OPTIONS = {
  mapId: process.env.GMAP_ID || '387b2dae642a706c',
  gridSize: cluster_size,
  minimumClusterSize: 2,
  minZoom: 1,
  maxZoom: 15,
};
