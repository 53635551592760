/*
  key: "configurations.name"
  label: "Name"
  sortable: true
  visible: true
*/
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';

// Imports => Constants
import { KEYS } from './keys.constants.js';
import { DATETIME_FORMATS } from './datetime.constants.js';

// Imports => Utilities
import { AcIsSet, AcFormatInternalURI } from '@utils';

// Imports => Atoms
const AcPill = loadable(() => import('@atoms/ac-pill/ac-pill.web'));

export const EQUIPMENT = [
  {
    key: 'object_no',
    label: 'Object number',
    visible: true,
  },
  {
    key: 'type.type',
    label: 'Type',
    visible: true,
  },
  {
    key: 'type.series',
    label: 'Series',
    visible: true,
  },
  {
    key: 'type.group',
    label: 'Equipment group',
    visible: true,
  },
];

export const PILE_LISTS = [
  {
    key: 'name',
    label: 'Pile name',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4, 5],
    editable: {
      type: 'string',
    },
    style: {
      'flex-basis': '15rem',
    },
  },
  {
    key: 'installation_sequence',
    label: 'Installation sequence',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4, 5],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '18rem',
    },
  },
  {
    key: 'pile_length',
    label: 'Length (m)',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4, 5],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '18rem',
    },
  },
  {
    key: 'top_outer_diameter',
    label: 'Top outer Ø (mm)',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4, 5],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '18rem',
    },
  },
  {
    key: 'bottom_outer_diameter',
    label: 'Bottom outer Ø (mm)',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '18rem',
    },
  },
  {
    key: 'top_thickness',
    label: 'Top thickness (mm)',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4, 5],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '18rem',
    },
  },
  {
    key: 'bottom_thickness',
    label: 'Bottom thickness (mm)',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '18rem',
    },
  },
  {
    key: 'marking_distance',
    label: 'Marking distance (m)',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4, 5],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '18rem',
    },
  },
  {
    key: 'target_penetration',
    label: 'Target penetration depth (m)',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4, 5],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '24rem',
    },
  },
  {
    key: 'latitude',
    label: 'Latitude',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4, 5],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '24rem',
    },
  },
  {
    key: 'longitude',
    label: 'Longitude',
    inline: true,
    visible: true,
    sortable: true,
    type: [1, 2, 3, 4, 5],
    editable: {
      type: 'number',
    },
    style: {
      'flex-basis': '24rem',
    },
  },
];

export const TRANSFERS = [
  {
    key: 'pile_list',
    label: 'Pile list',
    type: 'custom',
    meta: {
      format: (value) => {
        if (value?.name) return value?.name;
        return '-';
      },
    },
    visible: true,
  },
  {
    key: 'pile_count',
    label: 'Number of piles',
    type: 'custom',
    meta: {
      format: (value, row) => {
        if (AcIsSet(row?.available_pile_count)) {
          return `<strong>${value}</strong> / ${row?.available_pile_count}`;
        }

        return `<strong>${value}</strong>`;
      },
    },
    visible: true,
  },
  {
    key: 'extend',
    label: 'Method',
    type: 'custom',
    meta: {
      format: (value) => {
        if (value === false) return 'Overwrite';
        else if (value === true) return 'Extend';
      },
    },
    visible: true,
  },
  {
    key: 'equipment',
    label: 'Control unit',
    visible: true,
    type: 'custom',
    meta: {
      format: (value) => {
        if (AcIsSet(value?.object_no)) {
          return value?.object_no;
        }

        return '-';
      },
    },
  },
  {
    key: 'created_at',
    label: 'Sent to control unit',
    type: 'date',
    meta: {
      format: DATETIME_FORMATS.DATETIME_WITH_YEAR_AND_SECONDS,
    },
    visible: true,
  },
  {
    key: 'status',
    label: 'Status',
    type: 'custom',
    meta: {
      format: (value) => {
        switch (value) {
          case 'new':
            return <AcPill label={'NEW'} theme={'light'} />;
            break;

          case 'pending':
            return <AcPill label={'PENDING'} theme={'light'} />;
            break;

          case 'done':
            return <AcPill label={'TRANSFERED'} theme={'success'} />;
            break;

          case 'canceled':
            return <AcPill label={'FAILED'} theme={'error'} />;
            break;
        }
      },
    },
    visible: true,
  },
];

export const PROJECTS = [
  {
    key: 'name',
    label: 'Name',
    visible: true,
  },
  {
    key: 'date_start',
    label: 'Start date',
    visible: true,
  },
  {
    key: 'date_end',
    label: 'End date',
    visible: true,
  },
];

export const CONFIGURATION_PROJECTS = [
  {
    key: 'name',
    label: 'Name',
    visible: true,
    type: 'custom',
    meta: {
      format: (value, row) => {
        if (row?.id) {
          const link = {
            id: row.id,
            name: null,
            entity: KEYS.PROJECT,
            equipment_group: null,
          };

          const route = AcFormatInternalURI(link, value);

          return <Link to={route}>{value}</Link>;
        }

        return value;
      },
    },
  },
  {
    key: 'date_start',
    label: 'Start date',
    visible: true,
  },
  {
    key: 'date_end',
    label: 'End date',
    visible: true,
  },
];

export const CONFIGURATION_EQUIPMENT = [
  {
    key: 'name',
    label: 'Name',
    visible: true,
    type: 'custom',
    meta: {
      format: (value, row) => {
        if (!value) return '-';

        if (row?.id) {
          const link = {
            id: row.id,
            name: null,
            entity: KEYS.CONFIGURATION,
            equipment_group: null,
          };

          const route = AcFormatInternalURI(link, value);

          return <Link to={route}>{value}</Link>;
        }

        return value || '-';
      },
    },
  },
  {
    key: 'company',
    label: 'Company',
    visible: true,
    type: 'custom',
    meta: {
      format: (value, row) => {
        if (!value?.name) return '-';

        if (row?.company?.id) {
          const link = {
            id: row?.company?.id,
            name: null,
            entity: KEYS.COMPANY,
            equipment_group: null,
          };

          const route = AcFormatInternalURI(link, value?.name);

          return <Link to={route}>{value?.name}</Link>;
        }

        return value?.name || '-';
      },
    },
  },
];

export const OPERATORS = [
  {
    key: 'name',
    label: 'Name',
    visible: true,
  },
  {
    key: 'configurations',
    label: 'Configuration(s)',
    visible: true,
  },
];

export const CONTROL_UNIT_OPERATORS = [
  {
    key: 'name',
    label: 'Name',
    visible: true,
  },
  {
    key: 'username',
    label: 'Control unit Username',
    visible: true,
  },
  {
    key: 'password',
    label: 'Password',
    visible: true,
  },
];

export const SOFTWARE = [
  {
    key: 'name',
    label: 'Version number',
    visible: true,
  },
  {
    key: 'released_at',
    label: 'Released at',
    visible: true,
  },
  {
    key: 'available_at',
    label: 'Available since',
    visible: true,
  },
];

export const USERS = [
  {
    key: 'name',
    label: 'Name',
    visible: true,
  },
  {
    key: 'email',
    label: 'Email address',
    visible: true,
  },
  {
    key: 'id',
    label: 'User ID',
    visible: true,
  },
  {
    key: 'roles',
    label: 'User Roles',
    visible: true,
  },
];

export const COLUMNS = {
  CONFIGURATION_PROJECTS,
  CONFIGURATION_EQUIPMENT,
  CONTROL_UNIT_OPERATORS,
  EQUIPMENT,
  OPERATORS,
  PROJECTS,
  SOFTWARE,
  USERS,
  PILE_LISTS,
  TRANSFERS,
};

export default COLUMNS;
