export const EQUIPMENT = {};

export const EQUIPMENT_GROUPS = {
	HYDROHAMMERS: 1,
	POWERPACKS: 2,
	CONTROL_UNITS: 3,
	VIBRATORY_HAMMERS: 4,
	SLEEVES: 5,
	PULSES: 6,
};

export default EQUIPMENT;
